import gql from 'graphql-tag'

export const userFragment = gql`
  fragment UserFields on User {
    id
    email
    name
    isCurrentUser
  }
`

export const phoneNumberFragment = gql`
  fragment PhoneNumberFields on PhoneNumber {
    id
    number
    language
    comment
    usedInApp
    usedForEsign
    esignSessionId
    esignSessionStatus
    esignSessionUrl
  }
`
export const incomeSourceFragment = gql`
  fragment IncomeSourceFields on IncomeSource {
    id
    incomeType
    employerName
    employerAddressStreet
    employerAddressCity
    employerAddressState
    employerAddressZip
    employerPhone
    employerInsurance
    startMonth
    startYear
    endMonth
    endYear
    fullPartTime
    amount
    payFrequency
    usedForEsign
    esignSessionId
    esignSessionStatus
    esignSessionUrl
    proofOfIncome
    noProofOfIncomeReason
  }
`

export const deductionFragment = gql`
  fragment DeductionFields on Deduction {
    id
    deductionType
    startMonth
    startYear
    endMonth
    endYear
    amount
    deductionFrequency
  }
`

export const assetFragment = gql`
  fragment AssetFields on Asset {
    id
    assetType
    amount
  }
`

export const benefitOutcomeFragment = gql`
  fragment BenefitOutcomeFields on BenefitOutcome {
    id
    benefit
    coveredFacility
    outcome
    outcomeDate
    deniedIneligibleReason
    effectiveStartDate
    effectiveEndDate
    insuranceName
    policyId
    notes
    patientResponsibility
    createdAt
    updatedAt
  }
`

export const personFragment = gql`
  fragment PersonFields on Person {
    id
    updatedAt
    firstName
    middleName
    lastName
    suffix
    preferredName
    dob
    gender
    ssn
    phoneNumbers {
      ...PhoneNumberFields
    }
    homeAddressStreet
    homeAddressCity
    homeAddressState
    homeAddressZip
    homeAddressComment
    mailingAddressStreet
    mailingAddressCity
    mailingAddressState
    mailingAddressZip
    mailingAddressComment
    email
    emailComment
    preferredLanguage
    hospitalPatientId
    livesInNj
    hasInsurance
    ciStatus
    fiveYearsInUs
    specialCategory
    ciDocumentType
    pregnant
    maritalStatus
    spouseLiveWithMe
    expectedChild
    taxFilingStatus
    claimedBySomeoneOtherThanParents
    livingWithParentsWhoDoNotFileJointly
    estTaxDependentCount
    taxDependentInclSpouseLiveWithClient
    estParentLiveWithClient
    estChildren19LiveWithClient
    estMinor19SiblingLiveWithClient
    estHouseholdIncome
    incomeSources {
      ...IncomeSourceFields
    }
    deductions {
      ...DeductionFields
    }
    assets {
      ...AssetFields
    }
    currentInsuranceName
    currentInsurancePolicyNo
    insuranceStartdate
    njResidencyDoc
    ciDocumentName
    dateOfEntry
    uscisNo
    prCardNo
    dueDate
    spousePregnant
    isStudent
    dependentOfTaxFilerId
    changeJobInLast6Mon
    income
    whoSupportWithoutIncome
    othersSupportWithoutIncome
    selfSupportWithoutIncome
    insuranceLast3Months
    wantsCoverage
    alaskanNativeamerican
    fosterCareAfter18
    identityDocument
    desiredMco
    pcp
    childrenPcp
    note
    children18LiveWithClient
    minor18SiblingLiveWithClient
    pendingUsCitizenship
    livedInNjSinceMonth
    livedInNjSinceYear
    hasDeduction
    hasAsset
    noIncomeSinceMonth
    noIncomeSinceYear
    noAssetSinceMonth
    noAssetSinceYear
    homelessSinceMonth
    homelessSinceYear
    supporterRelationship
    supportSinceMonth
    supportSinceYear
    supportType
    supporterAddressStreet
    supporterAddressCity
    supporterAddressState
    supporterAddressZip
    supporterPhone
    othersAttestationWho
    othersAttestationRelationship
    othersAttestationPhone
    addressSinceMonth
    addressSinceYear
    race
    currentInsuranceType
    currentInsuranceIncludesDental
    noIdentityDocReason
    ownNonresidentProperty
    nonresidentPropertyMailingAddressStreet
    nonresidentPropertyMailingAddressCity
    nonresidentPropertyMailingAddressState
    nonresidentPropertyMailingAddressZip
    separatedSinceMonth
    separatedSinceYear
    dontLiveWithSpouse
    noJointAssetsWithSpouse
    noFinancialSupportFromSpouse
    notFiledJointlySince
    reasonNotFiling
    lastKnownAddressStreet
    lastKnownAddressCity
    lastKnownAddressState
    lastKnownAddressZip
    unreadMessageStatus
  }
  ${phoneNumberFragment}
  ${incomeSourceFragment}
  ${deductionFragment}
  ${assetFragment}
`

export const personForAwaitingPhoneNumberEsignSessionIdFragment = gql`
  fragment PersonForAwaitingPhoneNumberEsignSessionIdFields on Person {
    id
    phoneNumbers {
      ...PhoneNumberFields
    }
    incomeSources {
      ...IncomeSourceFields
    }
    relationships {
      otherPerson {
        id
        phoneNumbers {
          ...PhoneNumberFields
        }
        incomeSources {
          ...IncomeSourceFields
        }
      }
    }
  }
  ${phoneNumberFragment}
  ${incomeSourceFragment}
`

export const esignSessionFragment = gql`
  fragment EsignSessionFields on EsignSession {
    id
    unreviewed
  }
`

export const personForApplicationFragment = gql`
  fragment PersonForApplicationFields on Person {
    id
    firstName
    lastName
    middleName
    preferredName
    suffix
    dob
    ssn
    homeAddressStreet
    homeAddressCity
    homeAddressState
    homeAddressZip
    homeAddressComment
    mailingAddressStreet
    mailingAddressCity
    mailingAddressState
    mailingAddressZip
    mailingAddressComment
    phoneNumbers {
      ...PhoneNumberFields
      esignSession {
        ...EsignSessionFields
        esignWizards {
          id
          completedAt
        }
      }
    }
    preferredLanguage
    benefitOutcomes {
      ...BenefitOutcomeFields
      application {
        id
        ... on CharityCareApplication {
          charityCareType
        }
        ... on SlideApplication {
          slideType
        }
      }
    }
    incomeSources {
      ...IncomeSourceFields
      esignSession {
        ...EsignSessionFields
        esignWizards {
          id
          completedAt
        }
      }
    }
    deductions {
      ...DeductionFields
    }
    email
    hospitalPatientId
    unreadMessageStatus
  }
  ${phoneNumberFragment}
  ${benefitOutcomeFragment}
  ${incomeSourceFragment}
  ${deductionFragment}
  ${esignSessionFragment}
`

export const accountFragment = gql`
  fragment AccountFields on Account {
    id
    hospitalAccountId
    dateOfService
    timeOfService
    serviceTimeZone
    facility
    department
    location
    insuranceType
    status
    createdAt
    updatedAt
    notes
    accountAmount
    njmmisCheck
    previousInsurance
    invoiceMonth
    invoiceYear
    dischargeDate
    isScheduled
  }
`

export const taskFragment = gql`
  fragment TaskFields on Task {
    id
    taskType
    assignedTo {
      ...UserFields
    }
    createdBy {
      ...UserFields
    }
    status
    note
    durationMin
    dueDate
    linkedTo {
      ... on Person {
        id
        firstName
        lastName
        middleName
        preferredName
        suffix
      }
    }
    updatedAt
  }
  ${userFragment}
`

export const triageFragment = gql`
  fragment TriageFields on Triage {
    id
    account {
      ...AccountFields
    }
    createdAt
    updatedAt
    status
    closeReason
    closeComment
  }
  ${accountFragment}
`

export const eligibilityDeterminationFragment = gql`
  fragment EligibilityDeterminationFields on EligibilityDetermination {
    id
    benefit
    status
    reasonOrInfo
    secondaryInfo
    householdSizeDescription
    month
    numMonths
    createdAt
    additionalData
  }
`

export const openTriageFragment = gql`
  fragment OpenTriageFields on Triage {
    id
    status
    account {
      id
      hospitalAccountId
    }
    eligibilityDeterminations {
      ...EligibilityDeterminationFields
    }
  }
  ${eligibilityDeterminationFragment}
`

export const relationshipFragment = gql`
  fragment RelationshipFields on Relationship {
    id
    relationshipType
    reverseRelationshipType
    liveTogether
  }
`

export const relationshipWithFullPersonFragment = gql`
  fragment RelationshipWithFullPersonFields on Relationship {
    id
    relationshipType
    otherPerson {
      ...PersonFields
      fieldsUpdatedAt
    }
    liveTogether
    fieldsUpdatedAt
  }
  ${personFragment}
`

export const documentFileFragment = gql`
  fragment DocumentFileFields on DocumentFile {
    id
    name
    fileKey
    createdAt
  }
`

export const documentFragment = gql`
  fragment DocumentFields on Document {
    id
    documentType
    complete
    files {
      ...DocumentFileFields
    }
    updatedAt
    notes
    person {
      id
    }
  }
  ${documentFileFragment}
`

export const applicationFragment = gql`
  fragment ApplicationFields on Application {
    id
    benefit
    status
    createdAt
    updatedAt
    isUnclosableDueToMissingBenefitOutcomes
    accountNumber
    ... on MedicaidApplication {
      submitDate
      submitMethod
      location
      countyName
      confirmationNumber
      policyId
      notes
      initialDateOfService
      mcdType
      mcdSubtype
    }
    ... on MonitorApplication {
      submitDate
      applicationType
      submitMethod
      location
      countyName
      darSubmitDate
      confirmationNumber
      policyId
      notes
      primaryPointOfContact {
        id
      }
      initialDateOfService
    }
    ... on CharityCareApplication {
      initialDateOfService
      dateOfApplication
      requestedDateOfApplication
      notes
      facility
      coveragePeriod
      charityCareType
    }
    ... on SlideApplication {
      initialDateOfService
      receivedDate
      notes
      facility
      slideType
      dateOfApplication
      coveragePeriod
    }
    ... on RyanWhiteApplication {
      initialDateOfService
      receivedDate
      notes
      facility
      dateOfApplication
      coveragePeriod
    }
    detailsUpdatedAt
    readyForAudit
  }
`

export const applicationForStatusUpdateFragment = gql`
  fragment ApplicationForStatusUpdateFields on Application {
    id
    status
    person {
      id
      openApplications {
        id
        benefit
        ... on CharityCareApplication {
          facility
        }
        ... on SlideApplication {
          facility
        }
        ... on RyanWhiteApplication {
          facility
        }
      }
      openHouseholdMemberApplications {
        id
        benefit
      }
    }
  }
`

export const editableFileFragment = gql`
  fragment EditableFileFields on EditableFile {
    id
    fileKey
    filename
    updatedAt
    status
    allowSigning
    documentSendType
    faxEnabled
    sentFax {
      toNumber
      faxStatus
      message
      updatedAt
      countyFax {
        countyName
        state {
          stateCode
        }
      }
    }
  }
`

export const applicationQueryFragment = gql`
  fragment ApplicationQueryFields on Application {
    ...ApplicationFields
    person {
      ...PersonForApplicationFields
      relationships {
        ...RelationshipFields
        otherPerson {
          ...PersonForApplicationFields
        }
      }
      documentFiles {
        ...DocumentFileFields
      }
    }
    assignedTo {
      ...UserFields
    }
    householdMembers {
      id
      wantsCoverage
      person {
        ...PersonForApplicationFields
        documentFiles {
          ...DocumentFileFields
        }
      }
      createdAt
    }
    documents {
      ...DocumentFields
      person {
        id
      }
      application {
        id
      }
    }
    documentFiles {
      ...DocumentFileFields
      documents {
        id
      }
    }
    benefitOutcomes {
      ...BenefitOutcomeFields
      person {
        id
      }
      application {
        id
      }
    }
    accountOnDateOfService {
      ...AccountFields
    }
    ... on MedicaidApplication {
      primaryPointOfContact {
        ...PersonForApplicationFields
      }
    }
    ... on MonitorApplication {
      primaryPointOfContact {
        ...PersonForApplicationFields
      }
    }
    ...ApplicationForStatusUpdateFields
  }
  ${applicationFragment}
  ${personForApplicationFragment}
  ${relationshipFragment}
  ${documentFragment}
  ${documentFileFragment}
  ${benefitOutcomeFragment}
  ${applicationForStatusUpdateFragment}
  ${accountFragment}
  ${userFragment}
`

export const webformFragment = gql`
  fragment WebformFields on Webform {
    id
    name
    slug
  }
`

export const webformMergeAttemptFragment = gql`
  fragment WebformMergeAttemptFields on WebformMergeAttempt {
    id
    reviewed
    mergeResult
  }
`

export const receivedWebformFragment = gql`
  fragment ReceivedWebformFields on ReceivedWebform {
    id
    completedAt
    response
    noPersonMatch
    createdAt
  }
`

export const personQueryFragment = gql`
  fragment PersonQueryFields on Person {
    ...PersonFields
    triages {
      ...TriageFields
      eligibilityDeterminations {
        ...EligibilityDeterminationFields
      }
      assignedTo {
        ...UserFields
      }
    }
    openTriages {
      ...OpenTriageFields
    }
    relationships {
      ...RelationshipWithFullPersonFields
      otherPerson {
        openApplications {
          id
          person {
            id
          }
        }
        openHouseholdMemberApplications {
          id
          person {
            id
          }
        }
      }
    }
    allApplications {
      ...ApplicationFields
      person {
        ...PersonForApplicationFields
      }
      assignedTo {
        ...UserFields
      }
      householdMembers {
        id
        wantsCoverage
        person {
          id
        }
      }
      ...ApplicationForStatusUpdateFields
    }
    openApplications {
      ...ApplicationFields
      eligibilityDeterminations {
        ...EligibilityDeterminationFields
      }
      householdMembers {
        id
        person {
          id
        }
      }
      documents {
        ...DocumentFields
        person {
          id
        }
        application {
          id
        }
      }
      ...ApplicationForStatusUpdateFields
    }
    openHouseholdMemberApplications {
      ...ApplicationFields
      ...ApplicationForStatusUpdateFields
    }
    allOpenHouseholdMemberApplications {
      ...ApplicationFields
      ...ApplicationForStatusUpdateFields
      householdMembers {
        id
        person {
          id
        }
      }
      documents {
        ...DocumentFields
        person {
          id
        }
        application {
          id
        }
      }
      person {
        ...PersonFields
        relationships {
          ...RelationshipWithFullPersonFields
        }
      }
    }
    openPrimaryPointOfContactApplications {
      ...ApplicationFields
      ...ApplicationForStatusUpdateFields
    }
    benefitOutcomes {
      ...BenefitOutcomeFields
      application {
        ...ApplicationFields
      }
    }
    documentFiles {
      ...DocumentFileFields
      documents {
        id
      }
    }
    accounts {
      ...AccountFields
    }
    tasks {
      ...TaskFields
    }
    fieldsUpdatedAt
    webformMergeAttempts {
      ...WebformMergeAttemptFields
      receivedWebform {
        ...ReceivedWebformFields
        webform {
          ...WebformFields
        }
      }
    }
    mostRecentAccount {
      ...AccountFields
    }
    mostRecentEligibilityDeterminations {
      ...EligibilityDeterminationFields
    }
  }
  ${personFragment}
  ${triageFragment}
  ${openTriageFragment}
  ${relationshipWithFullPersonFragment}
  ${applicationFragment}
  ${personForApplicationFragment}
  ${eligibilityDeterminationFragment}
  ${benefitOutcomeFragment}
  ${documentFileFragment}
  ${documentFragment}
  ${accountFragment}
  ${taskFragment}
  ${applicationForStatusUpdateFragment}
  ${webformMergeAttemptFragment}
  ${webformFragment}
  ${receivedWebformFragment}
  ${userFragment}
`

export const personForAccountFragment = gql`
  fragment PersonForAccountFields on Person {
    id
    firstName
    lastName
    middleName
    preferredName
    dob
    hospitalPatientId
    suffix
    gender
    homeAddressStreet
    homeAddressCity
    homeAddressState
    homeAddressZip
    homeAddressComment
    mailingAddressStreet
    mailingAddressCity
    mailingAddressState
    mailingAddressZip
    mailingAddressComment
    phoneNumbers {
      ...PhoneNumberFields
    }
    email
    emailComment
    preferredLanguage
  }
  ${phoneNumberFragment}
`

export const facilityFragment = gql`
  fragment FacilityFields on Facility {
    id
    name
  }
`

export const departmentFragment = gql`
  fragment DepartmentFields on Department {
    id
    name
  }
`

export const financialOfficeFragment = gql`
  fragment FinancialOfficeFields on FinancialOffice {
    id
    name
  }
`

export const applicationBenefitFragment = gql`
  fragment ApplicationBenefitFields on ApplicationBenefit {
    id
    benefit
  }
`

export const lighticoWorkflowFragment = gql`
  fragment LighticoWorkflowFields on LighticoWorkflow {
    id
    name
  }
`
export const remoteRequestorTypeFragment = gql`
  fragment RemoteRequestorTypeFields on RemoteRequestorType {
    id
    requestorType
  }
`
export const remoteDocumentTypeFragment = gql`
  fragment RemoteDocumentTypeFields on RemoteDocumentType {
    id
    documentType
  }
`

export const charityCareTypeFragment = gql`
  fragment CharityCareTypeFields on CharityCareType {
    id
    name
  }
`

export const inboundReasonFragment = gql`
  fragment InboundReasonFields on InboundReason {
    id
    name
  }
`

export const configFragment = gql`
  fragment ConfigFields on Config {
    facilities {
      ...FacilityFields
    }
    departments {
      ...DepartmentFields
    }
    financialOffices {
      ...FinancialOfficeFields
    }
    applicationBenefits {
      ...ApplicationBenefitFields
    }
    lighticoWorkflows {
      ...LighticoWorkflowFields
    }
    remoteRequestorTypes {
      ...RemoteRequestorTypeFields
    }
    remoteDocumentTypes {
      ...RemoteDocumentTypeFields
    }
    charityCareTypes {
      ...CharityCareTypeFields
    }
    inboundReasons {
      ...InboundReasonFields
    }
    showTriage
    showDocumentFiles
    showEsignReview
    showAccounts
    showAudits
    maxFileSize
  }
  ${facilityFragment}
  ${departmentFragment}
  ${financialOfficeFragment}
  ${applicationBenefitFragment}
  ${lighticoWorkflowFragment}
  ${remoteRequestorTypeFragment}
  ${remoteDocumentTypeFragment}
  ${charityCareTypeFragment}
  ${inboundReasonFragment}
`

export const inboundFragment = gql`
  fragment InboundFields on Inbound {
    id
    reason
    dateOfService
    serviceType
    createdAt
    lastIntakeWebformReceivedAt
    inboundType
    insuranceOnDateOfService
  }
  ${userFragment}
`

export const njmmisCheckFragment = gql`
  fragment NjmmisCheckFields on NjmmisCheck {
    id
    status
    medicaidId
    eligibleServices
  }
`

export const inboundsQueryFragment = gql`
  fragment InboundsQueryFields on Inbound {
    ...InboundFields
    startedBy {
      ...UserFields
    }
    startedAt
    completedBy {
      ...UserFields
    }
    completedAt
    person {
      id
      firstName
      middleName
      lastName
      preferredName
      suffix
      dob
      openApplications {
        id
        benefit
      }
      mostRecentEligibilityDeterminations {
        ...EligibilityDeterminationFields
      }
    }
    personFormData {
      firstName
      lastName
      dob
      phoneNumber
    }
    mostRecentNjmmisCheck {
      ...NjmmisCheckFields
    }
    account {
      id
      department
      insuranceType
    }
  }
  ${eligibilityDeterminationFragment}
  ${inboundFragment}
  ${userFragment}
  ${njmmisCheckFragment}
`

export const fileTemplateFragment = gql`
  fragment FileTemplateFields on FileTemplate {
    id
    name
    fileKey
    allowSigning
    documentSendType
  }
`

export const inboundWebformFragment = gql`
  fragment InboundWebformFields on InboundWebform {
    id
    slug
    name
  }
`

export const stateFieldFragment = gql`
  fragment StateFields on State {
    id
    stateCode
    stateName
  }
`

export const countyFaxFragment = gql`
  fragment CountyFaxFields on CountyFax {
    id
    state {
      ...StateFields
    }
    countyName
    faxNumber
    emailAddress
    contactName
    contactEmail
    faxNumberEnabled
    emailAddressEnabled
  }
  ${stateFieldFragment}
`
