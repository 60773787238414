/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {Benefit} from './globalTypes' // ====================================================
// GraphQL query operation: Config
// ====================================================

import {Config as ConfigOrig} from '../types/Config'
import {Config_config as Config_configOrig} from '../types/Config'
import {Config_config_inboundReasons as Config_config_inboundReasonsOrig} from '../types/Config'
import {Config_config_charityCareTypes as Config_config_charityCareTypesOrig} from '../types/Config'
import {Config_config_remoteDocumentTypes as Config_config_remoteDocumentTypesOrig} from '../types/Config'
import {Config_config_remoteRequestorTypes as Config_config_remoteRequestorTypesOrig} from '../types/Config'
import {Config_config_lighticoWorkflows as Config_config_lighticoWorkflowsOrig} from '../types/Config'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {Config_config_applicationBenefits as Config_config_applicationBenefitsOrig} from '../types/Config'
import {Config_config_financialOffices as Config_config_financialOfficesOrig} from '../types/Config'
import {Config_config_departments as Config_config_departmentsOrig} from '../types/Config'
import {Config_config_facilities as Config_config_facilitiesOrig} from '../types/Config'
export interface Config_config_facilities {
  __typename: 'Facility'
  id: string
  name: string
}
export const deserializeConfig_config_facilities = (
  serialized: Config_config_facilitiesOrig
): Config_config_facilities => ({...serialized})
export const serializeConfig_config_facilities = (
  deserialized: Config_config_facilities
): Config_config_facilitiesOrig => ({...deserialized})
export interface Config_config_departments {
  __typename: 'Department'
  id: string
  name: string
}
export const deserializeConfig_config_departments = (
  serialized: Config_config_departmentsOrig
): Config_config_departments => ({...serialized})
export const serializeConfig_config_departments = (
  deserialized: Config_config_departments
): Config_config_departmentsOrig => ({...deserialized})
export interface Config_config_financialOffices {
  __typename: 'FinancialOffice'
  id: string
  name: string
}
export const deserializeConfig_config_financialOffices = (
  serialized: Config_config_financialOfficesOrig
): Config_config_financialOffices => ({...serialized})
export const serializeConfig_config_financialOffices = (
  deserialized: Config_config_financialOffices
): Config_config_financialOfficesOrig => ({...deserialized})
export interface Config_config_applicationBenefits {
  __typename: 'ApplicationBenefit'
  id: string
  benefit: BenefitType
}
export const deserializeConfig_config_applicationBenefits = (
  serialized: Config_config_applicationBenefitsOrig
): Config_config_applicationBenefits => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeConfig_config_applicationBenefits = (
  deserialized: Config_config_applicationBenefits
): Config_config_applicationBenefitsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface Config_config_lighticoWorkflows {
  __typename: 'LighticoWorkflow'
  id: string
  name: string
}
export const deserializeConfig_config_lighticoWorkflows = (
  serialized: Config_config_lighticoWorkflowsOrig
): Config_config_lighticoWorkflows => ({...serialized})
export const serializeConfig_config_lighticoWorkflows = (
  deserialized: Config_config_lighticoWorkflows
): Config_config_lighticoWorkflowsOrig => ({...deserialized})
export interface Config_config_remoteRequestorTypes {
  __typename: 'RemoteRequestorType'
  id: string
  requestorType: string
}
export const deserializeConfig_config_remoteRequestorTypes = (
  serialized: Config_config_remoteRequestorTypesOrig
): Config_config_remoteRequestorTypes => ({...serialized})
export const serializeConfig_config_remoteRequestorTypes = (
  deserialized: Config_config_remoteRequestorTypes
): Config_config_remoteRequestorTypesOrig => ({...deserialized})
export interface Config_config_remoteDocumentTypes {
  __typename: 'RemoteDocumentType'
  id: string
  documentType: string
}
export const deserializeConfig_config_remoteDocumentTypes = (
  serialized: Config_config_remoteDocumentTypesOrig
): Config_config_remoteDocumentTypes => ({...serialized})
export const serializeConfig_config_remoteDocumentTypes = (
  deserialized: Config_config_remoteDocumentTypes
): Config_config_remoteDocumentTypesOrig => ({...deserialized})
export interface Config_config_charityCareTypes {
  __typename: 'CharityCareType'
  id: string
  name: string
}
export const deserializeConfig_config_charityCareTypes = (
  serialized: Config_config_charityCareTypesOrig
): Config_config_charityCareTypes => ({...serialized})
export const serializeConfig_config_charityCareTypes = (
  deserialized: Config_config_charityCareTypes
): Config_config_charityCareTypesOrig => ({...deserialized})
export interface Config_config_inboundReasons {
  __typename: 'InboundReason'
  id: string
  name: string
}
export const deserializeConfig_config_inboundReasons = (
  serialized: Config_config_inboundReasonsOrig
): Config_config_inboundReasons => ({...serialized})
export const serializeConfig_config_inboundReasons = (
  deserialized: Config_config_inboundReasons
): Config_config_inboundReasonsOrig => ({...deserialized})
export interface Config_config {
  __typename: 'Config'
  facilities: Config_config_facilities[]
  departments: Config_config_departments[]
  financialOffices: Config_config_financialOffices[]
  applicationBenefits: Config_config_applicationBenefits[]
  lighticoWorkflows: Config_config_lighticoWorkflows[]
  remoteRequestorTypes: Config_config_remoteRequestorTypes[]
  remoteDocumentTypes: Config_config_remoteDocumentTypes[]
  charityCareTypes: Config_config_charityCareTypes[]
  inboundReasons: Config_config_inboundReasons[]
  showTriage: boolean
  showDocumentFiles: boolean
  showEsignReview: boolean
  showAccounts: boolean
  showAudits: boolean
  maxFileSize: number
}
export const deserializeConfig_config = (
  serialized: Config_configOrig
): Config_config => ({
  ...serialized,
  facilities: serialized.facilities.map(deserializeConfig_config_facilities),
  departments: serialized.departments.map(deserializeConfig_config_departments),
  financialOffices: serialized.financialOffices.map(
    deserializeConfig_config_financialOffices
  ),
  applicationBenefits: serialized.applicationBenefits.map(
    deserializeConfig_config_applicationBenefits
  ),
  lighticoWorkflows: serialized.lighticoWorkflows.map(
    deserializeConfig_config_lighticoWorkflows
  ),
  remoteRequestorTypes: serialized.remoteRequestorTypes.map(
    deserializeConfig_config_remoteRequestorTypes
  ),
  remoteDocumentTypes: serialized.remoteDocumentTypes.map(
    deserializeConfig_config_remoteDocumentTypes
  ),
  charityCareTypes: serialized.charityCareTypes.map(
    deserializeConfig_config_charityCareTypes
  ),
  inboundReasons: serialized.inboundReasons.map(
    deserializeConfig_config_inboundReasons
  ),
})
export const serializeConfig_config = (
  deserialized: Config_config
): Config_configOrig => ({
  ...deserialized,
  facilities: deserialized.facilities.map(serializeConfig_config_facilities),
  departments: deserialized.departments.map(serializeConfig_config_departments),
  financialOffices: deserialized.financialOffices.map(
    serializeConfig_config_financialOffices
  ),
  applicationBenefits: deserialized.applicationBenefits.map(
    serializeConfig_config_applicationBenefits
  ),
  lighticoWorkflows: deserialized.lighticoWorkflows.map(
    serializeConfig_config_lighticoWorkflows
  ),
  remoteRequestorTypes: deserialized.remoteRequestorTypes.map(
    serializeConfig_config_remoteRequestorTypes
  ),
  remoteDocumentTypes: deserialized.remoteDocumentTypes.map(
    serializeConfig_config_remoteDocumentTypes
  ),
  charityCareTypes: deserialized.charityCareTypes.map(
    serializeConfig_config_charityCareTypes
  ),
  inboundReasons: deserialized.inboundReasons.map(
    serializeConfig_config_inboundReasons
  ),
})
export interface Config {
  /**
   * Environment-specific configuration
   */
  config: Config_config
}
export const deserializeConfig = (serialized: ConfigOrig): Config => ({
  ...serialized,
  config: deserializeConfig_config(serialized.config),
})
export const serializeConfig = (deserialized: Config): ConfigOrig => ({
  ...deserialized,
  config: serializeConfig_config(deserialized.config),
})
